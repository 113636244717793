import { CurrentUserFeaturesEnum } from '@/src/api/generated';
import { useAccount } from '@/src/hooks/useAccount';
import { useGetRouterQueryValue } from '@/src/hooks/useGetRouterQueryValue';
import { gaEventClick } from '@/src/utils/gtag';

import { EffectivenessVerifications } from './EffectivenessVerifications';
import { Groups } from './Groups';
import { Rankings } from './Rankings';
import { Referrals } from './Referrals';
import { ReverseReferrals } from './ReverseReferrals';
import { Segments } from './Segments';
import { Summary } from './Summary';
import { Visits } from './Visits';
import { DataAnalysisPresenter, Templates } from './presenter';

import { useRouter } from 'next/router';

type TabElement = {
  label: string;
  value: Templates;
  isVisible: boolean;
  template: JSX.Element;
};

export const DataAnalysis: React.FC = () => {
  const account = useAccount().account;

  const hasGroupMedicalInstitution =
    (account.tenant?.groupMedicalInstitutions?.length ?? 0) > 0;

  const TAB_ELEMENTS: TabElement[] = [
    {
      label: 'サマリー',
      value: 'summary',
      isVisible: true,
      template: <Summary />,
    },
    {
      label: '紹介数',
      value: 'referrals',
      isVisible: true,
      template: <Referrals />,
    },
    {
      label: '逆紹介数',
      value: 'reverseReferrals',
      isVisible: account.features.includes(
        CurrentUserFeaturesEnum.ReverseReferrals,
      ),
      template: <ReverseReferrals />,
    },
    {
      label: '連携先セグメント',
      value: 'segments',
      isVisible: true,
      template: <Segments />,
    },
    {
      label: '訪問数',
      value: 'visits',
      isVisible: true,
      template: <Visits />,
    },
    {
      label: '個別効果検証',
      value: 'effectivenessVerifications',
      isVisible: true,
      template: <EffectivenessVerifications />,
    },
    {
      label: 'ランキング',
      value: 'rankings',
      isVisible: true,
      template: <Rankings />,
    },
    {
      label: 'グループ病院',
      value: 'groups',
      isVisible: hasGroupMedicalInstitution,
      template: <Groups />,
    },
  ];

  const PRIMARY_TAB: Templates = 'summary';

  const { pathname } = useRouter();

  const getRouterQueryValue = useGetRouterQueryValue();
  const selectedTab = getRouterQueryValue('tab') as Templates;

  const dataAnalysisContentsProps = (): React.ComponentProps<
    typeof DataAnalysisPresenter
  >['dataAnalysisContents'] => {
    return {
      values: TAB_ELEMENTS.filter((t) => t.isVisible).map((t) => ({
        label: t.label,
        value: t.value,
      })),
      selectedValue: selectedTab ?? PRIMARY_TAB,
      pathName: pathname,
      onClickValue: (v: Templates) => gaEventClick(`data_analysis?tab=${v}`),
    };
  };

  return (
    <DataAnalysisPresenter
      dataAnalysisContents={dataAnalysisContentsProps()}
      selectedTemplate={
        TAB_ELEMENTS.find((t) => t.value === (selectedTab ?? PRIMARY_TAB))
          ?.template
      }
    />
  );
};
