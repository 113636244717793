import { Card } from '@/src/components/foundations/DataDisplays/Card';
import { LoadingCircle } from '@/src/components/foundations/Feedback/LoadingCircle';
import { CenteringContainer } from '@/src/components/foundations/Layouts/CenteringContainer';
import { Stack } from '@/src/components/foundations/Layouts/Stack';
import { useGetFirstReferral } from '@/src/hooks/fetcher/useGetFirstReferral';
import { useGetLastReferral } from '@/src/hooks/fetcher/useGetLastReferral';
import { usePostMedicalInstitutionReportAggregationResults } from '@/src/hooks/fetcher/usePostMedicalInstitutionReportAggregationResults';
import { useScrollContainerWidth } from '@/src/hooks/useScrollContainerWidth';
import {
  currentFiscalYear,
  currentHalfYear,
  generateHalfYearSequence,
  toHalfYear,
} from '@/src/utils/date';
import { generateSequence } from '@/src/utils/generateSequence';

import { useAction } from './action';
import { ChartWithControl } from './components/ChartWithControl';
import { FilterForm } from './components/FilterForm';
import { Table } from './components/Table';
import {
  toRequestParameterForFiscalYear,
  toRequestParameterForHalfYear,
} from './mapper';
import { actionType, useSummaryState } from './reducer';
import {
  toChartData,
  toTableData,
  facilityTypeFiltersToVisibleTableRows,
} from './selector';

export const Summary: React.FC = () => {
  const firstReferral = useGetFirstReferral();
  const lastReferral = useGetLastReferral();
  if (!firstReferral.data || !lastReferral.data) {
    return (
      <CenteringContainer>
        <LoadingCircle />
      </CenteringContainer>
    );
  }

  return (
    <SummaryContainer
      firstReferralDate={
        firstReferral.data.referralDate
          ? new Date(firstReferral.data.referralDate)
          : null
      }
      lastReferralDate={
        lastReferral.data.referralDate
          ? new Date(lastReferral.data.referralDate)
          : null
      }
    />
  );
};

const MIN_CONTENT_WIDTH = 1250;
const MAIN_CONTENT_HORIZONTAL_PADDING = 80;

export const SummaryContainer: React.FC<{
  firstReferralDate: Date | null;
  lastReferralDate: Date | null;
}> = (props) => {
  const { state, dispatch } = useSummaryState();
  const { scrollContainerWidth } = useScrollContainerWidth();
  const { csvDownload } = useAction();

  const fiscalYearPeriods = generateSequence(
    props.firstReferralDate?.getFullYear() ?? currentFiscalYear().getFullYear(),
    props.lastReferralDate?.getFullYear() ?? currentFiscalYear().getFullYear(),
  );
  const halfYearPeriods = generateHalfYearSequence(
    props.firstReferralDate
      ? toHalfYear(props.firstReferralDate)
      : currentHalfYear(),
    props.lastReferralDate
      ? toHalfYear(props.lastReferralDate)
      : currentHalfYear(),
  );
  const results = usePostMedicalInstitutionReportAggregationResults(
    state.dateUnit === 'year'
      ? toRequestParameterForFiscalYear(fiscalYearPeriods, state.filters)
      : toRequestParameterForHalfYear(halfYearPeriods, state.filters),
  );

  const tableData = toTableData(
    results,
    state.dateUnit === 'year' ? fiscalYearPeriods : halfYearPeriods,
  );

  return (
    <Stack align="stretch" gap={24} width="initial">
      <FilterForm
        filters={state.filters}
        setFilters={(filters) =>
          dispatch({ type: actionType.setFilters, payload: filters })
        }
        onClickCsvExport={() =>
          tableData
            ? csvDownload(
                'サマリー',
                tableData,
                state.dateUnit,
                facilityTypeFiltersToVisibleTableRows(
                  state.filters.facilityTypeIds,
                ),
              )
            : undefined
        }
      />
      <Card>
        <Stack
          align="stretch"
          gap={0}
          width={
            Math.max(scrollContainerWidth, MIN_CONTENT_WIDTH) -
            MAIN_CONTENT_HORIZONTAL_PADDING
          }
        >
          <ChartWithControl
            dateUnit={state.dateUnit}
            setDateUnit={(dateUnit) =>
              dispatch({ type: actionType.setDateUnit, payload: dateUnit })
            }
            data={toChartData(
              results,
              state.dateUnit === 'year' ? fiscalYearPeriods : halfYearPeriods,
            )}
          />
          <Table
            dateUnit={state.dateUnit}
            data={tableData}
            visibleRows={facilityTypeFiltersToVisibleTableRows(
              state.filters.facilityTypeIds,
            )}
          />
        </Stack>
      </Card>
    </Stack>
  );
};
