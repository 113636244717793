import styles from './styles.module.scss';

import clsx from 'clsx';
import Link from 'next/link';
import { useRef } from 'react';

type Props<V> = {
  values: { label: string; value: V; attention?: boolean }[];
  selectedValue: V;
  onClickValue: (v: V) => void;
  pathName?: string;
  isSp?: boolean;
};

export const Tab: <T extends string>(props: Props<T>) => JSX.Element = (
  props,
) => {
  const buttonContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={buttonContainerRef}
      className={clsx(styles.base, {
        [styles.sp]: props.isSp,
      })}
    >
      {props.values?.map((v) => {
        return props.pathName ? (
          <Link
            key={v.value}
            href={{ pathname: props.pathName, query: { tab: v.value } }}
            legacyBehavior
          >
            <button
              className={clsx(
                props.selectedValue === v.value && styles.active,
                v.attention && styles.attention,
              )}
              onClick={() => {
                props.onClickValue(v.value);
              }}
            >
              {v.label}
            </button>
          </Link>
        ) : (
          <button
            className={clsx({
              [styles.active]: props.selectedValue === v.value,
              [styles.attention]: v.attention,
              [styles.sp]: props.isSp,
            })}
            key={v.value}
            onClick={(e) => {
              props.onClickValue(v.value);

              if (props.isSp && buttonContainerRef.current) {
                const buttonRect = e.currentTarget.getBoundingClientRect();
                const scrollLeft =
                  buttonRect.left -
                  buttonContainerRef.current.getBoundingClientRect().left +
                  buttonContainerRef.current.scrollLeft;

                // クリックした要素が、範囲内にすべて表示されるように、スクロールさせてる
                buttonContainerRef.current.scrollTo({
                  left: scrollLeft,
                  behavior: 'smooth',
                });
              }
            }}
          >
            {v.label}
          </button>
        );
      })}
    </div>
  );
};
