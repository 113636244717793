import {
  GetOwnDepartmentsResponse,
  PostChartReferralsComparisonDepartmentsResponse,
} from '@/src/api/generated';
import {
  Loading,
  WIDE_CHART_SIZE,
} from '@/src/components/features/DataAnalytics/Charts';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';
import { ChartPagination } from '@/src/components/foundations/Charts/ChartPagination';

import { createChartData } from './selector';
import styles from './styles.module.scss';

import { useMemo } from 'react';

type ContentsLoading = {
  status: 'loading';
};
type ContentsNormal = {
  status: 'normal';
  referrals: PostChartReferralsComparisonDepartmentsResponse['referrals'];
  departments: GetOwnDepartmentsResponse['departments'];
  pagination: Omit<React.ComponentProps<typeof ChartPagination>, 'totalPage'>;
};

type Props = {
  contents: ContentsNormal | ContentsLoading;
};

export const DepartmentUnitCardPresenter: React.FC<Props> = (props) => {
  const contents = props.contents;

  const chartData = useMemo(
    () =>
      contents.status === 'normal'
        ? createChartData(contents.referrals, contents.departments)
        : null,
    [contents],
  );

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className={styles.title}>診療科別比較</div>
        <div className={styles.control}>
          {contents.status === 'normal' &&
            chartData &&
            chartData.dividedCounts >= 2 && (
              <ChartPagination
                {...contents.pagination}
                totalPage={chartData.dividedCounts}
              />
            )}
        </div>
      </div>
      <div className={styles.chart}>
        {((): JSX.Element => {
          switch (contents.status) {
            case 'loading':
              return <Loading />;
            case 'normal':
              return chartData === null ? (
                <Loading />
              ) : (
                <BarChart
                  size={WIDE_CHART_SIZE}
                  mode="mixStack"
                  data={chartData.calcData(contents.pagination.currentPage)}
                  payload={chartData.payload}
                  charts={chartData.charts}
                  shortenNameLength
                />
              );
          }
        })()}
      </div>
    </div>
  );
};
